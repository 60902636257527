@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;
  background-color: #dbf8f8;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  background-color: lightgray;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
i {
  color: white;
  font-size: 3vw;
  margin: 1vh 1vw;
}
header {
  font-size: 4vh;
  height: 8vh;
  background-color: #283145;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  border-bottom: white solid 3px;
  box-shadow: 2px 10px 20px rgba(0,0,0,.3);
}
header a, header a:visited {
  text-decoration: none;
  color: white;
  margin: 0 3vw;
}

#home-section {
  height:100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
 #home-section img {
  height:65vh;
  margin-top: 20vh;
  box-shadow: 2px 4px 10px rgba(0,0,0,.3);
  border: #283145 solid 4px;
}
.sideofpic {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
}
.homecard {
  position: relative;
  right: 1.5vw;
  top: 1.7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #8d8699;
  height:  35vh;
  width: 70vh;
  color: white;
  box-shadow: 2px 10px 20px rgba(0,0,0,.3);
  border: white 3px solid;

}
.homecard h1 {
  margin: 5vh 0;
  font-size: 5.5vh;
}
.homecard div {
  font-size: 3vh;
  margin: 1vh 1.2vh;
  line-height: 1.6;
}
.myname {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #283145;
  vertical-align: middle;
  height: 20vh;
  font-size: 10vh;
  font-weight: 700;
  position: relative;
  right: 1.5vw;
}
.section-title {
  padding-top: 10vh;
  padding-bottom: 4vh;
  color: #283145;
  vertical-align: middle;
  font-size: 10vh;
  font-weight: 700;
}

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.project {

  background-color: white;
  display: flex;
  flex-direction: row;
  border: 5px solid  #283145;
  box-shadow: 2px 10px 20px rgba(0,0,0,.3);
  margin-bottom: 5vh;
}
.odd {
  margin-right: 12vw;
}
.even {
  margin-left: 12vw;
}
.project img {
  width: 35vw;
  box-shadow: 2px 4px 10px rgba(0,0,0,.3);
  border: #283145 solid 4px;
  margin: 6vh 0;
}
.project-img {
  width: 45vw;
}
.project-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #283145;
  color: white;
  width: 30vw;
  padding: 0 2vw
}
.project-text h2 {
  margin: 0;
  font-size: 3.5vw;
}
.project-text div {
  font-size: 1.2vw;
}
.links a, .links a:visited {
  background-color: #8d8699;
  color: white;
  font-weight: 900;
  font-size: 1.8vw;
  text-align: center;
  text-decoration: none;
  padding: 2vh 0;
  width: 10vw;
  border: 5px solid white;
  box-shadow: 2px 10px 20px rgba(0,0,0,.3);
}
.links a:hover {
  background-color: white;
  color: #8d8699;
  border: 5px solid #8d8699;

}
.links {
  height: 7vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
svg {
  color:#283145;
  width: 6.5vw;
}
svg:hover{
  color:#8d8699
}
#about-section {
  min-height: 100vh;
}

.about-info {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.about-text{
  margin-right: 2vw;
  padding: 2vw;
  line-height: 1.6;
  height: 25vw;
  width: 40vw;
  font-size: 1.8vw;
  background-color: #8d8699;
  color: white;
  border: 4px white solid;
  box-shadow: 2px 4px 10px rgba(0,0,0,.3);

}
.about-links{
display: flex;
flex-direction: column;
justify-content: space-between;
height: 30vw;
margin-left: 2vw;
}
.about-links div {
  display: flex;
  align-items: center;
  font-size: 2vw;
}
.about-links p {
margin-left: 2vw;
}

@media only screen and (max-width: 800px) {
  #home-section {
    flex-direction: row-reverse;
  }
  #home-section img {
    display: none;
 
  }
  header {
    justify-content: space-evenly;
  }
  .homecard {
    position: relative;
    top: 0;
    right: 0;
    max-width: 95vw;
  }
  .myname {
    right: 0;
  }
  .odd {
    margin: 2vh 0;
    display: flex;
    flex-direction: column;
  }
  .even {
    margin: 2vh 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .project img {
    width: 70vw;
    margin: 6vh 0;
  }
  .project-img {
    width: 80vw;
  }
  .project-text {
    width: 80vw;
    padding: 2vh 2vw;
  }
  .project-text h2 {
    margin: 1vh 0;
    font-size: 6vw;
  }
  .project-text div {
    font-size: 3vw;
    margin: 1vh;
    line-height: 1.3;
  }
  .links a, .links a:visited {
    font-size: 4vw;
  }
  .links {
    height: 8vh;
  }
  i {
    font-size: 7vw;
    margin: 2vh 2vw;
  }
  svg {
    width: 11vw;
  }
  .about-info {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about-text{
    margin: 0;
    padding: 2vw;
    line-height: 1.3;
    height: 35vw;
    width: 70vw;
    font-size: 3.2vw;
    background-color: #8d8699;
    color: white;
    border: 4px white solid;
    box-shadow: 2px 4px 10px rgba(0,0,0,.3);
  
  }
  .about-links{
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  height: 25vw;
  width: 95vw;
  margin-left: 0;
  }
  .about-links div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3.5vw;
    width: 35vw;
  }
  .about-links p {
    margin: 2vh 0;
  }


}


